import { Link as NavLink, useStaticQuery, graphql } from 'gatsby'
import type { ComponentProps } from 'react'
import React, { useMemo, useState } from 'react'

import type { JobsListQuery, Maybe } from '../../../../../graphql-types'
import { ENGINEERING_PLACEHOLDER_JOB_SLUG } from '../../../../constants'
import Switcher from '../../../forms/Switcher'
import { BodyRegular } from '../../../typography'
import { getLinkProps } from '../../../utils'

import {
  ContainerCenter,
  InfoBox,
  InfoBoxContent,
  InfoBoxIcon,
  Job,
  LearnMore,
  Outer,
  SwitcherContainer,
  Title,
  Text,
  Location,
} from './styled'

export const query = graphql`
  query JobsList {
    list: allContentfulJobListing(
      sort: { fields: orderIndex, order: ASC }
      filter: { node_locale: { eq: "en" } }
    ) {
      items: nodes {
        ...MinimalJob
      }
    }
  }
`

const addCountryFlag = (location: string) =>
  location.replace('France', 'France 🇫🇷').replace('USA', 'USA 🇺🇸')

const AssistantInfo = ({
  copy,
  ctaUrl,
}: {
  copy?: Maybe<string>
  ctaUrl?: Maybe<string>
}) => (
  <InfoBox>
    <InfoBoxIcon>{'💡'}</InfoBoxIcon>
    <InfoBoxContent>
      <BodyRegular>{copy}</BodyRegular>
      <LearnMore href={ctaUrl || ''}>{'Learn more'}</LearnMore>
    </InfoBoxContent>
  </InfoBox>
)

type Props = ComponentProps<typeof Outer> & {
  assistantInfo?: Maybe<string>
  ctaUrl?: Maybe<string>
}

const JobsList = (props: Props) => {
  const data = useStaticQuery<JobsListQuery>(query)
  const [showAssistants, setShowAssistants] = useState(true)

  const items = useMemo(
    () =>
      (data?.list?.items || [])
        .filter((item) => item.slug !== ENGINEERING_PLACEHOLDER_JOB_SLUG)
        .filter((item) => (showAssistants ? !item.isHq : item.isHq))
        .filter(Boolean),
    [data?.list?.items, showAssistants],
  )

  return (
    <ContainerCenter>
      <SwitcherContainer>
        <Switcher
          leftLabel={'Assistants'}
          rightLabel={'HQ Team'}
          isLeft={showAssistants}
          onSwitch={setShowAssistants}
          $width={330}
        />
      </SwitcherContainer>

      {!!items.length && (
        <Outer {...props}>
          {items.map((job) => (
            <NavLink key={job.slug || ''} to={getLinkProps(job)?.href || ''}>
              <Job>
                <Title>{job.title}</Title>
                <Location>
                  {addCountryFlag((job.locations || []).join(', '))}
                </Location>
              </Job>
            </NavLink>
          ))}
        </Outer>
      )}

      {!!items.length || (
        <Text>
          {showAssistants
            ? 'No assistant positions at this time.'
            : 'No HQ positions at this time.'}
        </Text>
      )}

      {showAssistants && (
        <AssistantInfo ctaUrl={props.ctaUrl} copy={props.assistantInfo} />
      )}
    </ContainerCenter>
  )
}

export default JobsList
