export const SIGNUP_LEAD_STATE_KEY = 'signup-lead'

export const NEW_SIGNUP_PATH = '/sign-up/why-double'

export const CAREERS_PATH = '/careers'

// 👀 We need to keep at least one instance of a job listing in Contentful,
// otherwise, the Gatsby build breaks. So, we've created a placeholder job
// listing that we can use to prevent this from happening. And then we ignore
// it when displaying the job listings.
export const ENGINEERING_PLACEHOLDER_JOB_SLUG = 'engineering-placeholder-job'
